import type { ComponentPublicInstance, PropType } from 'vue'
import {
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useVueTable,
} from '@tanstack/vue-table'
import { useScroll, useCssVar, useElementSize } from '@vueuse/core'
import { Spinner } from '#components'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import TableFooter from './TableFooter'
import TableSkeleton from './TableSkeleton'
import Pagination from './Pagination'
import VirtualHorizontalScrollbar from './VirtualHorizontalScrollbar'
import type { DataTableProps } from './types'
import {
  __useDataTableVariants,
  usePagination,
  useVirtualScroller,
  useRowSelection,
  useSort,
  useInitializeColumns,
  useVirtualScrollbar,
  useStickyHeader,
  useProvideTableStore,
  useGap,
  useColumnSizing,
  useColSizesVars,
  useInfiniteScroll,
  useResizable,
} from './composables'
import {
  GapFeature,
  ColumnPinningFeature,
  ColumnSizingFeature,
  ColumnVisibilityFeature,
  VirtualScrollerFeature,
} from './features'

export default defineComponent({
  name: 'DataTable',
  props: {
    // BASE
    data: {
      type: Array as PropType<DataTableProps['data']>,
      required: true,
      default: () => [],
    },
    columns: {
      type: Array as PropType<DataTableProps['columns']>,
      required: true,
      default: () => [],
    },
    loading: {
      type: Boolean as PropType<DataTableProps['loading']>,
      default: false,
    },
    fetching: {
      type: Boolean as PropType<DataTableProps['fetching']>,
      default: false,
    },
    fetchingMessage: {
      type: String as PropType<DataTableProps['fetchingMessage']>,
      default: 'Updating...',
    },
    skeletonCount: {
      type: Number as PropType<DataTableProps['skeletonCount']>,
      default: 10,
    },
    persist: {
      type: Object as PropType<DataTableProps['persist']>,
      default: undefined,
    },
    // PAGINATION
    pagination: {
      type: Object as PropType<DataTableProps['pagination']>,
      default: undefined,
    },
    totalRecords: {
      type: Number as PropType<DataTableProps['totalRecords']>,
      default: 0,
    },
    rowsPerPageOptions: {
      type: Array as PropType<DataTableProps['rowsPerPageOptions']>,
      default: () => [25, 50, 100],
    },
    infiniteScroll: {
      type: Boolean as PropType<DataTableProps['infiniteScroll']>,
      default: false,
    },
    // SELECTION
    selection: {
      type: Object as PropType<DataTableProps['selection']>,
      default: undefined,
    },
    // SORT
    sortable: {
      type: [Boolean, String] as PropType<DataTableProps['sortable']>,
      default: false,
    },
    sort: {
      type: Array as PropType<DataTableProps['sort']>,
      default: () => [],
    },
    // RESIZE
    resizable: {
      type: Boolean as PropType<DataTableProps['resizable']>,
      default: false,
    },
    // EXPAND
    expandable: {
      type: Function as PropType<DataTableProps['expandable']>,
      default: undefined,
    },
    // VIRTUAL SCROLLER
    virtualScroller: {
      type: Object as PropType<DataTableProps['virtualScroller']>,
      default: undefined,
    },
    // STYLES
    footer: {
      type: Boolean as PropType<DataTableProps['footer']>,
      default: false,
    },
    hoverable: {
      type: Boolean as PropType<DataTableProps['hoverable']>,
      default: true,
    },
    clickable: {
      type: Boolean as PropType<DataTableProps['clickable']>,
      default: false,
    },
    sticky: {
      type: [Boolean, Object] as PropType<DataTableProps['sticky']>,
      default: true,
    },
    stripedRows: {
      type: Boolean as PropType<DataTableProps['stripedRows']>,
      default: false,
    },
    showGridlines: {
      type: Boolean as PropType<DataTableProps['showGridlines']>,
      default: false,
    },
    padding: {
      type: String as PropType<DataTableProps['padding']>,
      default: 'md',
    },
    rounded: {
      type: Boolean as PropType<DataTableProps['rounded']>,
      default: false,
    },
    height: {
      type: String as PropType<DataTableProps['height']>,
      default: undefined,
    },
  },
  emits: [
    'click:row',
    'update:pagination',
    'update:selection',
    'update:sort',
    'fetchNextPage',
    'render',
  ],
  setup(props, { emit, slots, expose }) {
    // DATA & COLUMNS
    const data = computed(() => props.data)
    const columns = useInitializeColumns(props)

    // GAP
    const { gap, setGap } = useGap(props, emit)

    // PAGINATION
    const { pageCount, tablePagination, pagination, setPagination } =
      usePagination(props, emit)

    // ROW SELECTION
    const { selection, setSelection } = useRowSelection(props, emit)

    // SORTING
    const { sort, setSort } = useSort(props, emit, () => {
      if (rows.value.length && rowVirtualizer.value) {
        rowVirtualizer.value.scrollToIndex?.(0)
      }
    })

    // COLUMN SIZING
    const {
      columnSizing,
      setColumnSizing,
      columnSizingInfo,
      setColumnSizingInfo,
    } = useColumnSizing(props, emit)

    // VIRTUAL SCROLLER
    const hasVirtualScroller = computed(() => !!props.virtualScroller)

    // RESIZE
    const { isResizable } = useResizable(props, { hasVirtualScroller })

    const table = useVueTable({
      _features: [
        VirtualScrollerFeature,
        ColumnSizingFeature,
        GapFeature,
        ColumnPinningFeature,
        ColumnVisibilityFeature,
      ],
      data,
      get columns() {
        return columns.value
      },
      defaultColumn: {
        minSize: 60,
        maxSize: 800,
      },
      state: {
        get pagination() {
          return tablePagination.value
        },
        get rowSelection() {
          return selection.value
        },
        get sorting() {
          return sort.value
        },
        get gap() {
          return gap.value
        },
        get columnSizing() {
          return columnSizing.value
        },
        get columnSizingInfo() {
          return columnSizingInfo.value
        },
      },
      // Gap
      enableGap: true,
      onGapChange: setGap,
      // Pagination
      get pageCount() {
        return pageCount.value
      },
      manualPagination: true,
      onPaginationChange: setPagination,
      // Resize
      get enableColumnResizing() {
        return isResizable.value
      },
      columnResizeMode: 'onChange',
      // Row selection
      enableRowSelection: true,
      getCoreRowModel: getCoreRowModel(),
      onRowSelectionChange: setSelection,
      // Sorting
      get enableSorting() {
        return !!props.sortable
      },
      sortDescFirst: false,
      getSortedRowModel: getSortedRowModel(),
      onSortingChange: setSort,
      get manualSorting() {
        return props.sortable === 'manual'
      },
      // Visibility
      enableHiding: true,
      // Column Sizing
      onColumnSizingChange: setColumnSizing,
      onColumnSizingInfoChange: setColumnSizingInfo,
      // Virtual Scroller
      get enableVirtualScroller() {
        return hasVirtualScroller.value
      },
      // Sub Rows
      getSubRows: props.expandable,
      getExpandedRowModel: getExpandedRowModel(),
    })

    const headerGroups = computed(() => table.getHeaderGroups())
    const rows = computed(() => table.getRowModel().rows)
    const visibleColumns = computed(() => [
      ...table.getLeftVisibleLeafColumns(),
      ...table.getCenterVisibleLeafColumns(),
      ...table.getRightVisibleLeafColumns(),
    ])

    // REFS
    const wrapperRef = ref<HTMLDivElement | null>(null)
    const tableRef = ref<HTMLDivElement | null>(null)
    const scrollbarRef = ref<InstanceType<
      typeof VirtualHorizontalScrollbar
    > | null>(null)

    //set the divider height of the col resizer
    const cssVar = useCssVar('--table-col-resize-height', tableRef)
    const { height } = useElementSize(tableRef)
    watchDebounced(
      height,
      (newHeight) => {
        const hasGroup = headerGroups.value.length > 1
        const header =
          tableRef.value?.querySelector('thead')?.children[
            headerGroups.value.length - 1
          ]

        if (newHeight !== undefined) {
          if (hasGroup && header) {
            cssVar.value = `${newHeight - header.getBoundingClientRect().height}px`
          } else {
            cssVar.value = `${newHeight}px`
          }
        }
      },
      { debounce: 300 },
    )

    useInfiniteScroll(props, emit, { wrapperRef })

    // VIRTUALIZER
    const {
      columnVirtualizer,
      rowVirtualizer,
      virtualColumns,
      virtualRows,
      virtualPadding,
      hasVirtualColumns,
      hasVirtualRows,
    } = useVirtualScroller({
      wrapperRef,
      tableRef,
      rows,
      visibleColumns,
      virtualScroller: toRef(props, 'virtualScroller'),
      height: toRef(props, 'height'),
      table,
    })

    const { arrivedState } = useScroll(wrapperRef)

    // VIRTUAL HORIZONTAL SCROLLBAR
    const { tableSize, showVirtualScrollbar } = useVirtualScrollbar({
      wrapperRef,
      tableRef,
      scrollbarRef: computed(() =>
        scrollbarRef.value?.$el.querySelector('div'),
      ),
      height: toRef(props, 'height'),
    })

    // STICKY HEADER
    const { isSticky, offsetHeader } = useStickyHeader(props, tableRef)

    // COL SIZES VARS
    const columnSizeVars = useColSizesVars({ table, columnSizingInfo })

    // VARIANTS
    const variants = __useDataTableVariants(props, {
      type: hasVirtualScroller.value ? 'grid' : 'table',
      hoverable: props.hoverable,
      clickable: props.clickable,
      sticky: isSticky.value,
      hasScroller: !props.height,
      stripedRows: props.stripedRows,
      showGridlines: props.showGridlines,
      padding: props.padding,
      rounded: props.rounded,
      hasFooter: props.footer,
    })

    const onClickRow = (value: any) => {
      if (props.clickable) {
        emit('click:row', value)
      }
    }

    const onRender = () => {
      nextTick(() => {
        setTimeout(() => {
          emit('render')
        }, 100)
      })
    }

    // PROVIDE
    useProvideTableStore(props, {
      table,
      columnVirtualizer,
      rowVirtualizer,
      virtualColumns,
      virtualRows,
      variants,
      hasVirtualScroller,
      hasVirtualColumns,
      hasVirtualRows,
    })

    // EXPOSE
    expose({
      getRowVirtualizer: () => rowVirtualizer.value,
    })

    return () => (
      <div class={variants.container()}>
        {/* FIRST LOADING */}
        {!props.infiniteScroll && !props.loading && props.fetching && (
          <div>
            {slots.fetching?.() ?? (
              <div class="p-4">
                <span class="text-xs text-gray-500">
                  <Spinner class="fill-primary" size="sm" />
                  {props.fetchingMessage}
                </span>
              </div>
            )}
          </div>
        )}

        {!props.loading && slots.empty && props.data.length === 0 ? (
          <div class="h-full">{slots.empty()}</div>
        ) : (
          <div
            ref={wrapperRef}
            class={variants.wrapper()}
            style={{ height: props.height }}
            data-arrived-left={arrivedState.left}
            data-arrived-right={arrivedState.right}
          >
            {props.loading ? (
              <TableSkeleton
                ref={(vNode) =>
                  (tableRef.value = (
                    vNode as ComponentPublicInstance | undefined
                  )?.$el)
                }
                skeletonCount={
                  tablePagination.value?.pageSize ?? props.skeletonCount
                }
                headers={table.getFlatHeaders()}
                variants={variants}
                virtualScroller={props.virtualScroller}
                offsetHeader={offsetHeader.value}
              />
            ) : (
              <table
                ref={tableRef}
                class={variants.table()}
                style={{
                  ...columnSizeVars.value,
                  ...virtualPadding.value,
                  width: hasVirtualColumns.value
                    ? `${table.getTotalSize()}px`
                    : undefined,
                }}
              >
                {/* HEADER */}
                <TableHeader
                  groups={headerGroups.value}
                  offsetHeader={offsetHeader.value}
                />

                {/* BODY */}
                <TableBody
                  rows={rows.value}
                  virtualRows={virtualRows.value}
                  virtualColumns={virtualColumns.value}
                  tableSlots={slots}
                  onClick:row={(value) => onClickRow(value)}
                  onMounted={() => onRender()}
                />

                {/* FOOTER */}
                {props.footer && (
                  <TableFooter groups={table.getFooterGroups()} />
                )}
              </table>
            )}
          </div>
        )}

        {/* PAGINATION */}
        {props.pagination && props.data.length > 0 && (
          <Pagination
            loading={props.loading}
            rowsPerPageOptions={props.rowsPerPageOptions}
            pagination={table.getState().pagination}
            totalRecords={props.totalRecords}
            canNextPage={table.getCanNextPage()}
            canPreviousPage={table.getCanPreviousPage()}
            onPreviousPage={() => table.previousPage()}
            onNextPage={() => table.nextPage()}
            onPageSize={(value: number) => table.setPageSize(value)}
          />
        )}

        {/* Virtual Scroll */}
        {showVirtualScrollbar.value && (
          <VirtualHorizontalScrollbar
            ref={scrollbarRef}
            tableSize={tableSize.width.value}
          />
        )}
      </div>
    )
  },
})
